/* Base styles */
.container-padding {
  padding: 100px 0px !important;
}
.our-leadership {
  display: grid;
  align-items: center;
  gap: 1rem;
  grid-template-columns: 40% 60%;
  margin-top: 1rem;
}

.ing {
  height: clamp(549px, 5vw, 657px) !important;
}

.our-leadership-sub-header-title-text {
  color: #000;
  font-family: "Abhaya Libre" !important;
  font-weight: 800 !important;
  font-size: clamp(16px, 5vw, 24px) !important;
  line-height: clamp(32px, 5vw, 32px) !important;
  letter-spacing: 0%;
  vertical-align: middle;
  text-align: left;
  margin: 0px !important;
}

.our-leadership-header-title-text {
  font-family: "Abhaya Libre" !important;
  font-weight: 800 !important;
  font-size: clamp(36px, 5vw, 48px) !important;
  line-height: clamp(41px, 5vw, 60px) !important;
  letter-spacing: 1%;
  vertical-align: middle;
  text-align: left;
}

.our-leadership-description-text {
  color: #000;
  font-family: "Poppins" !important;
  font-weight: 400 !important;
  font-size: clamp(12px, 5vw, 14px) !important;
  line-height: 24px !important;
  letter-spacing: 0%;
  vertical-align: middle;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .our-leadership {
    grid-template-columns: 100%;
  }

  .header-text {
    font-size: 36px;
    line-height: 48px;
  }
}

@media (max-width: 576px) {
  .container-padding {
    padding: 50px 0px !important;
  }
  .sub-header-title-text {
    font-family: "Abhaya Libre" !important;
    font-weight: 800 !important;
    text-align: center;
  }
  .header-title-text {
    font-family: "Abhaya Libre" !important;
    font-weight: 800 !important;
    text-align: center;
  }
}
