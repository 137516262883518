/* Base styles for header text */
.our-gallery-sub-header-title-text {
  color: #000;
  font-family: "Abhaya Libre" !important;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0%;
  vertical-align: middle;
  margin: 0px !important;
}

.our-gallery-header-title-text {
  font-family: "Abhaya Libre", serif;
  font-weight: 800;
  font-size: 48px;
  line-height: 60px;
}

.our-gallery-description-text {
  color: #000;
  font-family: "Poppins" !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1%;
  vertical-align: middle;
}

/* Base styles for gallery grids */
.gallery-grid-1,
.gallery-grid-2 {
  margin-top: 1rem;
  display: grid;
  gap: 0.5rem;
}

.gallery-grid-1 {
  grid-template-columns: 50% 25% 25%;
}

.gallery-grid-2 {
  grid-template-columns: 25% 50% 25%;
}

/* Mobile responsive styles */
@media (max-width: 576px) {
  .sub-header-title-text {
    color: #000 !important;
    font-family: "Abhaya Libre" !important;
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0%;
    vertical-align: middle;
  }

  .header-title-text {
    font-family: "Abhaya Libre", serif;
    font-weight: 800;
    font-size: 38px;
    line-height: 60px;
  }

  .description-text {
    color: #000;
    font-family: "Poppins" !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 1%;
    vertical-align: middle;
  }

  .gallery-grid-1,
  .gallery-grid-2 {
    grid-template-columns: 100%;
  }
}
