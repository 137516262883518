/* Responsive grid for different screen sizes */
.container-padding {
  padding: 100px 0px !important;
}

.our-commitment-sub-title-text {
  color: #0a0a0a !important;
  font-family: "Abhaya Libre" !important;
  font-weight: 800 !important;
  font-size: clamp(18px, 4vw, 24px) !important;
  line-height: 32px;
  letter-spacing: "0%";
  text-transform: capitalize !important;
}

.our-commitment-title-text {
  font-family: "Abhaya Libre" !important;
  font-weight: 800;
  font-size: clamp(32px, 4vw, 48px) !important;
  line-height: clamp(39px, 4vw, 56px) !important;
  letter-spacing: 1%;
  text-align: center;
  vertical-align: middle;
}

.icon {
  width: clamp(80px, 4vw, 150px) !important;
  height: clamp(80px, 4vw, 150px) !important;
}

.item-card {
  border-width: 1px;
  border-radius: 20px;
}

.item-header-text {
  color: #510000;
  font-family: "Abhaya Libre" !important;
  font-weight: 800 !important;
  font-size: 30px !important;
  line-height: 130%;
  letter-spacing: -1%;
  text-align: center;
  text-transform: uppercase;
  margin-top: clamp(2.5rem, 4vw, 5rem) !important;
  margin-bottom: clamp(1rem, 4vw, 2rem) !important;
}

.item-description-text {
  color: #000;
  font-family: "Poppins" !important;
  font-weight: 400;
  font-size: clamp(12px, 4vw, 14px) !important;
  line-height: 150%;
  letter-spacing: 0%;
}

@media (max-width: 1200px) {
}

@media (max-width: 992px) {
}

@media (max-width: 768px) {
}

@media (max-width: 576px) {
  .container-padding {
    padding: 100px 0px !important;
  }

  .item-header-text {
    color: #510000;
    font-family: "Abhaya Libre" !important;
    font-weight: 800 !important;
    font-size: 30px !important;
    line-height: 130%;
    letter-spacing: -1%;
    text-align: center;
    text-transform: uppercase;
    margin-top: 5rem;
    margin-bottom: 2rem;
  }
}
