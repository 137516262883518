/* Responsive grid for different screen sizes */
.container-padding {
  padding: 100px 0px !important;
}

.affilate-program-section {
  display: grid;
  margin-top: 1rem;
  gap: 0.5rem;
  grid-template-columns: 50% 50%;
}

.affilate-program-section-header-title-text {
  font-family: "Abhaya Libre", serif;
  font-weight: 800 !important;
  font-size: clamp(36px, 4vw, 52px) !important;
  line-height: clamp(36px, 4vw, 56px) !important;
}

@media (max-width: 768px) {
}

@media (max-width: 576px) {
  .container-padding {
    padding: 50px 0px !important;
  }

  .affilate-program-section {
    gap: 3rem;
    grid-template-columns: 100%;
  }
}
